/* eslint-disable no-undef */
(function () {
  const { gtmId } = document.currentScript.dataset;
  const DEFAULT_CONTAINER_ID = 'GTM-PTGNXM';
  const DOMAINS_WITH_PRODUCTION_GTM = [
    'clark.de',
    'www.clark.de',
    'goclark.at',
    'www.goclark.at',
  ];
  const STORAGE_ITEMS = ['vwoSn', '_vwo'];
  const COOKIE_PREFIXES = ['_ga', '_gcl_au', '_gid', '_vis', '_vwo', 'mp_'];
  const GTM_ID = DOMAINS_WITH_PRODUCTION_GTM.includes(window.location.hostname)
    ? gtmId
    : DEFAULT_CONTAINER_ID;

  function isTrackingCookieEnabled() {
    const cookieName = 'tracking-marketing';
    const cookieValue = document.cookie.match(
      `(^|;) ?${cookieName}=([^;]*)(;|$)`,
    );
    const mcookie = cookieValue ? cookieValue[2] : null;
    return mcookie && mcookie === 'true';
  }

  window.dataLayer = window.dataLayer || [];

  let gtmTagInjected = false;

  // This function is used in the Ember app also. This should not be modified to
  // use some other logics.
  function loadClarkTrackingScript() {
    if (!gtmTagInjected) {
      gtmTagInjected = true;
      window.dataLayer.push({
        'gtm.start': Date.now(),
        event: 'gtm.js',
      });
      const [firstScript] = document.getElementsByTagName('script');
      const gtmScript = document.createElement('script');
      gtmScript.async = true;
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`;
      firstScript.parentNode.insertBefore(gtmScript, firstScript);
    }
  }

  async function checkConsentAndLoadTrackingScript() {
    const featureEnabled = await ConsentInspector.featureEnabled();
    if (featureEnabled) {
      ConsentInspector.executePerConsent(
        'marketing_&_tracking',
        COOKIE_PREFIXES,
        STORAGE_ITEMS,
        loadClarkTrackingScript,
      );
    } else if (isTrackingCookieEnabled()) {
      loadClarkTrackingScript();
    }
  }

  window.loadClarkTrackingScript = loadClarkTrackingScript;

  checkConsentAndLoadTrackingScript();
})();
